const sortDate = (a, b) => {
  return new Date(a.date) - new Date(b.date)
}

/**
 * Force change UTC "Time"
 * @param value {Date}
 * @param dayTime {string} - 'start' | 'end'
 * @returns {string}
 */
const formatDate = (value, dayTime) => {
  const localDate = new Date(value)
  dayTime === 'start' ? localDate.setUTCHours(0, 0, 0, 0) : localDate.setUTCHours(23, 59, 59, 999)
  return localDate.toISOString()
}

/**
 * Checks if the given date string is in the past.
 * @param {string} dateString - The ISO date string (e.g., "2025-02-19T15:52:00.000Z").
 * @returns {boolean} - Returns true if the date is in the past, false otherwise.
 */
const isPastDate = (dateString) => {
  if (!dateString) return false
  const now = new Date()
  const targetDate = new Date(dateString)

  if (isNaN(targetDate.getTime())) {
    return false
  }

  return targetDate < now
}

export {
  sortDate,
  formatDate,
  isPastDate
}
