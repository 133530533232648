export const REDIRECT_STATE_ALIAS = 'Alias'
export const REDIRECT_STATE_REDIRECT = 'Redirect'

export const REDIRECT_TYPE_DEFAULT = REDIRECT_STATE_ALIAS

export default {
  computed: {
    redirectTypeStateValues () {
      return [
        {
          id: REDIRECT_STATE_ALIAS,
          title: this.$t('redirectVlm.aliasTypes.alias')
        },
        {
          id: REDIRECT_STATE_REDIRECT,
          title: this.$t('redirectVlm.aliasTypes.redirect')
        }
      ]
    }
  }
}
