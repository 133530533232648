export const MENU_EDITOR_STATE_ACTIVE = true
export const MENU_EDITOR_STATE_INACTIVE = false

export const MENU_EDITOR_TYPE_DEFAULT = MENU_EDITOR_STATE_ACTIVE

export default {
  computed: {
    menuEditorStateValues () {
      return [
        {
          id: MENU_EDITOR_STATE_ACTIVE,
          title: this.$t('menueditor.type_value.active')
        },
        {
          id: MENU_EDITOR_STATE_INACTIVE,
          title: this.$t('menueditor.type_value.inactive')
        }
      ]
    }
  }
}
