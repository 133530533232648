<template>
  <div class="site-select">
    <label class="site-select__label" :class="{'site-select__label--error' : error}">
      {{ label }}
      <span v-if="required">*</span>
    </label>

    <MultiSelect
      ref="multiSelect"
      :value="modelValue"
      @input="onInput"
      :options="sortedSites"
      label="title"
      track-by="id"
      :placeholder="$t('type_to_search')"
      :multiple="false"
      :disabled="disabled"
      selected-label=""
      deselect-label="X"
      :select-label="''"
      @search-change="handleSearch"
      :class="{'multiselect--error': error}"
    >
      <template #option="{ option }">
        <div :class="{'site-select__item-divider' : isFirstNonRegionalSite(option.id)}">
          {{ option.title }}
          <span v-if="isFirstNonRegionalSite(option.id)" class="site-select__item-divider__divider"></span>
        </div>
      </template>

      <span slot="noResult">{{ $t('multiselect_no_result') }}</span>
    </MultiSelect>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'

export default {
  name: 'SiteSelect',
  components: {
    MultiSelect
  },
  props: {
    value: {
      type: [Number, Object, String],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: 'Title'
    },
    permissionModule: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    // The property to use as the value from the selected option's object
    valueProp: {
      type: String,
      default: 'id'
    }
  },
  data () {
    return {
      firstNonRegionalSite: null
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    sites () {
      return this.options.length ? this.options : this.$store.getters['site/enabledSites'](this.permissionModule)
    },
    sortedSites () {
      const sites = this.sites
      sites.sort((a, b) => a.title.localeCompare(b.title))

      sites.sort((a, b) => {
        if (a.regionId && b.regionId) {
          return 0
        } else if (a.regionId) {
          return -1
        } else if (b.regionId) {
          return 1
        } else {
          return 0
        }
      })
      return sites
    },
    modelValue () {
      if (this.value && typeof this.value !== 'object') {
        const site = this.sites.find(site => site[this.valueProp] === this.value) || null
        // Custom parameter for VLM
        if (this.vlm && site) {
          this.$emit('update', { field: 'name', value: site.name })
          return site
        }
      }

      return this.value
    }
  },
  mounted () {
    this.firstNonRegionalSite = this.sortedSites.find(site => !site.regionId)?.id
  },
  created () {
    if (!this.options.length && !this.permissionModule) {
      throw new Error('Provide either sites or permission module')
    }
  },
  methods: {
    handleSearch () {
      this.firstNonRegionalSite = this.$refs.multiSelect.filteredOptions.find(site => !site.regionId)?.id
    },
    isFirstNonRegionalSite (id) {
      return id === this.firstNonRegionalSite
    },
    onInput (value) {
      this.$emit('input', value ? value[this.valueProp] : null)
    }
  }
}
</script>
<style src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import 'src/assets/scss/components/multiselect';
</style>
<style lang="scss" scoped>
.site-select {
  &__label {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    color: #8A96AC;

    &--error {
      color: var(--danger);
    }
  }

  ::v-deep {
    .multiselect {
      .multiselect__tags {
        border: 1px solid #d1dbe4;
      }

      &--error {
        .multiselect__tags {
          border: 1px solid var(--danger);
        }
      }

      &__option {
        &:has(.site-select__item-divider) {
          margin-top: rem(8px);
        }
      }

      .multiselect__single, .multiselect__placeholder {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #465674
      }

      .multiselect__placeholder, .multiselect__input, ::placeholder {
        color: lighten(#465674, 30%);
      }
    }
  }

  &__item-divider {
    position: relative;

    &__divider {
      background-color: #000;
      height: 1px;
      left: 0;
      position: absolute;
      top: rem(-16px);
      width: 100%;
    }
  }
}
</style>
